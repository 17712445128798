<template>
  <div>
    <el-tabs 
		v-model="activeTab"  
		@tab-click="changeTabs">
		<el-tab-pane
			:label="item.name"
			:key="index"
			:name="item.code"
			v-for="(item, index) in tabsList"
		>
        <div v-if="orderList.length > 0" v-loading="orderLoading">
          <div
            class="order-list"
            v-for="(item, index) in orderList"
            :key="index"
          >
            <el-card class="box-card">
              <div class="list-header">
                <div class="header-left">
                  <span>订单号：{{ item.order_no }}</span>
                  <span>创建时间：{{ item.create_time }}</span>
                </div>
                <div class="order-flag order-success" v-if="item.status == 1">
                  交易成功
                </div>
                <div class="order-flag no-pay" v-else>未支付</div>
              </div>
              <ul class="list-con">
                <li class="order-img">
                  <img
                    v-if="item.thumb"
                    :src="item.thumb"
                    :title="item.title"
                  />
                  <img
                    v-else
                    :src="require('@/assets/images/default-course-cover.png')"
                    :title="item.title"
                  />
                </li>
                <li class="order-title">
                  <span>{{ item.title }}</span>
                </li>
                <li class="order-type">
                  课程类型：{{
                    item.type == 1
                      ? "精品套餐"
                      : item.type == 2
                      ? "基础班级"
                      : "题库套餐"
                  }}
                </li>
                <li class="order-price">
                  售价：<span class="price">￥{{ item.sale_money }}</span>
                </li>
              </ul>
              <div class="list-footer">
                <div class="total-money">
                  <span class="text">实付金额：</span>
                  <span class="num">￥{{ item.order_money }}</span>
                </div>
                <div class="order-btn">
                  <el-button
                    v-show="item.status == 0"
                    size="mini"
                    @click="payOrderBtn(item)"
                    type="success"
                    >立即付款</el-button
                  >
                  <el-button
                    v-show="item.status == 0"
                    size="mini"
                    @click="removeOrderBtn(item)"
                    type="danger"
                    >取消订单</el-button
                  >
                  <!-- <el-button
                     size="mini"
                     @click='orderDetailBtn(item)'
                     type='primary'>订单详情</el-button> -->
                </div>
              </div>
            </el-card>
          </div>
        </div>
        <div v-else>
          <el-empty description="暂无订单数据"></el-empty>
        </div>
      </el-tab-pane>
    </el-tabs>
	
	<!-- 支付方式 -->
	<el-dialog
	  title="请选择支付方式"
	  v-model="dialogPayment"
	  width='30%'
	  :before-close="handleClose"
	>
	  <div class='pay-type'>
	    <el-button @click='zfbPayBtn'>
	     <div class='pay-title'>
	       <svg-icon icon-class='zhifubao' class='tab-icon'></svg-icon>
	       <span>支付宝</span>
	     </div>
	    </el-button>
	    <el-button @click='wxPayBtn'>
	     <div class='pay-title'>
	       <svg-icon icon-class='weixinzhifu' class='tab-icon'></svg-icon>
	       <span>微 信</span>
	     </div>
	    </el-button>
	  </div>
	</el-dialog>
	

    <!-- 支付宝支付 -->
    <el-dialog
    	v-model="dialogAliPayment"
    	width='25%'
    	:before-close="handleAlipayClose"
    >
    	<template #title>
    		<div class='alipay-title'>
    			<svg-icon icon-class='zhifubao' class='ali-icon'></svg-icon>
    			<span>支付宝</span>
    		</div>
    	</template>
    	<el-result 
    		icon="warning" 
    		title="订单支付中..." 
    		subTitle="请确认支付情况" >
    	  <template #extra>
    		<el-button
    			@click="handleAlipayClose" 
    			type="primary" 
    			size="medium">完成支付</el-button>
    	  </template>
    	</el-result>
    </el-dialog>

	

    <!-- 微信支付 -->
    <el-dialog
    	v-model="dialogWxPayment"
    	width='25%'
    	:before-close="handlewxPayClose"
    >
    	<template #title>
    		<div class='wxpay-title'>
    			<svg-icon icon-class='weixinzhifu' class='wx-icon'></svg-icon>
    			<span>微信</span>
    		</div>
    	</template>
    	<div class='wx-code'>
    	  <div id="qrcode" ref="qrCode"></div>
    	</div>
    	<template #footer>
    		<div class="wx-footer">
    			<el-button 
    				@click="handlewxPayClose"
    				class='wx-btn'
    				type='danger'>出现问题</el-button>
    			<el-button
    				class='wx-btn'
    				@click="handlewxPayClose" 
    				type='success'>支付成功</el-button>
    		</div>
    	</template>
    </el-dialog>
	
  </div>
</template>

<script type="text/javascript">
import QRCode from "qrcodejs2";
import {
	removeOrder, 
	aliPay, 
	wxPay, 
	myOrderAll, 
	aliPayCom,
	wxPayState } from "@/api/user";

export default {
  data() {
    return {
		dialogAliPayment:false,//支付宝对话框
		dialogWxPayment:false, //微信对话框
		dialogPayment: false,
		orderLoading: true,
		activeTab: "0",
		tabsList: [
		{ name: "全部", code: "0" },
		{ name: "待付款", code: "1" },
		{ name: "已付款", code: "2" },
		],
		allOrderList: [],
		orderList: [],
		currentPayOrder: null
    };
  },
  created() {
		this.orderListFun();
  },
  methods: {
    orderListFun() {
      myOrderAll().then((res) => {
        if (res.code == 1) {
          this.orderLoading = false;
          if (this.activeTab == 0) {
            //全部
            this.orderList = res.data[0].concat(res.data[1]);
          }
          if (this.activeTab == 1) {
            //未支付
            this.orderList = res.data[1];
          }
          if (this.activeTab == 2) {
            //已经支付
            this.orderList = res.data[0];
          }
        }
      });
    },
    changeTabs(tab, event) {
		//切换tab
		this.orderListFun();
    },
	payOrderBtn(curorder){//立即支付按钮
	  //判断是否已经支付
	  let _this = this;
	  this.currentPayOrder = curorder;
	  
	  aliPayCom({
	  	order_no:curorder.order_no
	  }).then(res=>{
	  	if(res.code==1){
	  		this.$notify({
	  			title: '支付成功',
	  			message: '支付成功',
	  			type: 'success',
	  			duration:1500,
	  			showClose:false
	  		});
	  		this.dialogPayment = false;
	  		this.orderListFun();
	  	}else{
	  		wxPayState({
	  			order_no:curorder.order_no
	  		}).then(res=>{
	  			if(res.code==1){
	  				this.$notify({
	  					title: '支付成功',
	  					message: '支付成功',
	  					type: 'success',
	  					duration:1500,
	  					showClose:false
	  				});
	  				this.dialogPayment = false;
	  				this.orderListFun();
	  			}else{
	  				this.dialogPayment = true;
	  			}
	  		});
	  	}
	  });
	},
	handleClose() {//关闭支付对话框
	  this.dialogPayment = false;
	},
	zfbPayBtn() {//支付宝
		this.dialogPayment = false;
		aliPay({
			product_id: this.currentPayOrder.product_id,
			title: this.currentPayOrder.title,
			order_no: this.currentPayOrder.order_no,
			sale_money: this.currentPayOrder.sale_money,
		}).then((res) => {
			let payhtml = res.substr(0, res.length - 1);
			let routerData = this.$router.resolve({
			  path: "/orderalipay",
			  query: { payData: payhtml },
			});
			window.open(routerData.href, "_ blank");
			//跳转支付成功页面
			this.dialogAliPayment = true;
		});
	},
	handleAlipayClose(){//判断支付宝是否支付
		let _this = this;
		aliPayCom({
			order_no:this.currentPayOrder.order_no,
		}).then(res=>{
			if(res.code==1&&res.data.code=='10000'){
				let _this = this;
				this.$notify({
					title: '支付成功',
					message: '支付成功',
					type: 'success',
					duration:1500,
					showClose:false,
					onClose:function(){
						_this.$router.push({
							path:'/paysuccess'
						})
					}
				})
			}else{
				this.$notify.error({
					title: '支付失败',
					message: '请重新支付',
					duration:1500,
					showClose:false
				});
			}
			this.dialogAliPayment = false;
		})
	},
	wxPayBtn() {
		//微信
		this.dialogPayment = false;
		wxPay({
			product_id: this.currentPayOrder.product_id,
			title: this.currentPayOrder.title,
			order_no: this.currentPayOrder.order_no,
			sale_money:this.currentPayOrder.sale_money*100
		}).then((res) => {
			this.dialogWxPayment = true;
			this.$nextTick(function() {
			  this.qrcode(200, 200, res, "canvas");
			});
		});
	},
	qrcode(qWidth, qHeight, qText, qRender) {
	  let qrcode = new QRCode("qrcode", {
	    width: qWidth,
	    height: qHeight,
	    text: qText,
	    render: qRender,
	  });
	},
    handleCloseWx() {
      //关闭微信支付
      this.dialogWxment = false;
      this.$refs.qrCode.innerHTML = "";
    },
    handlewxPayClose() {
      //微信支付成功
		wxPayState({
			order_no:this.currentPayOrder.order_no,
		}).then(res=>{
			if(res.code==1){
				this.dialogWxPayment = false;
				this.$refs.qrCode.innerHTML = '';
				this.$notify({
					title: '支付成功',
					message: '支付成功',
					type: 'success',
					duration:1500,
					showClose:false,
					onClose:function(){
						_this.$router.push({
							path:'/paysuccess'
						})
					}
				});
			}else{
				this.dialogWxPayment = false;
				this.$refs.qrCode.innerHTML = '';
				this.$notify.error({
					title: '支付失败',
					message: '请重新支付',
					duration:1500,
					showClose:false
				});
			}
		});
    },
    removeOrderBtn(data) { //取消订单
      this.$confirm("取消订单, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
          removeOrder({
            order_no: data.order_no,
          }).then((res) => {
            if (res.code == 1) {
              this.$notify({
                title: "取消成功",
                message: "取消订单成功",
                type: "success",
                duration: 1500,
                showClose: false,
              });
              this.orderListFun();
            }
          });
        }).catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    orderDetailBtn(data) {
		console.log(data);
      // this.$router.push({
      //   path: "/coursedetail/" + data.product_id + "/" + data.type,
      // });
    },
  },
};
</script>

<style scoped lang="scss">
.box-card {
  padding: 10px;
  margin-bottom: 10px;
}
.list-header {
  padding: 10px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  border-bottom: 1px solid #e8e8e8;
}
.list-header .header-left {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.list-header .header-left span {
  margin-right: 30px;
}
.list-header .order-flag.order-success {
  color: $success-color;
}
.list-header .order-flag.no-pay {
  color: $danger-color;
}

.list-con {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100px;
  padding: 15px 0px;
  border-bottom: 1px solid #ccc;
  .order-img {
    width: 150px;
  }
  .order-title {
    margin-left: 20px;
    width: 350px;
    overflow: hidden; /*超出部分隐藏*/
    white-space: nowrap; /*不换行*/
    text-overflow: ellipsis; /*超出部分文字以...显示*/
  }
  .order-type {
    flex: 1;
    margin-left: 20px;
    font-size: 12px;
  }
  .order-price {
    flex: 1;
    font-size: 12px;
    .price {
      font-size: 14px;
      color: $price-color;
    }
  }
}

.list-footer {
  height: 50px;
  padding: 10px 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.list-footer .total-money {
  margin-right: 30px;
}
.list-footer .total-money .num {
  font-size: 18px;
  color: $price-color;
}

.pay-type {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 30px 0px;
  .pay-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    .tab-icon {
      margin-right: 10px;
      font-size: 24px;
      color: red;
    }
  }
}
.ewm {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.wxpay-title{
		display: flex;
		flex-direction: row;
		align-items: center;
		font-size:16px;
		padding-bottom: 10px;
		border-bottom: 1px solid #ccc;
		.wx-icon{
			font-size:20px;
			margin-right:10px;
		}
	}
	.wx-code{
		padding:20px 0px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}
	.wx-footer{
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		.wx-btn{
			margin:0px 20px;
		}
	}
</style>
